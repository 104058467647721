@import "../navbar/navbar.scss";

$sidePanelWidth: 370px;

@mixin primaryText {
  color: #222;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@mixin inputOrBtn {
  cursor: pointer;
  border-radius: 8px;
  border: 1.5px solid #ddd;
  padding: 8px 12px;
  background: #fff;
  outline: none;
}

.vertical-divider {
  height: 100%;
  width: 1px;
  background: rgba(0, 0, 0, 0.05);
}

.image-carousel {
  width: 100%;
  position: relative;
  overflow: hidden;

  .carousel-container {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
  }

  .carousel-image {
    width: 100%;
    min-width: 100%;
    height: auto;
    opacity: 0;
    object-fit: cover;
  }

  .carousel-image.loaded {
    opacity: 1;
  }

  video {
    &.carousel-image {
      height: 450px;
    }
  }

  .dot-container {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
  }

  .dot-wrapper {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
  }

  .dot {
    height: 10px;
    width: 10px;
    align-self: center;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
  }

  .dot.active {
    opacity: 1;
  }

  .carousel-control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .carousel-control {
      position: absolute;
      top: calc(50% - 21px);
      height: 42px;
      width: 42px;
      border-radius: 50%;
      background-color: white;
      z-index: 1;
      left: 16px;
      cursor: pointer;
      display: flex;
      justify-content: center;

      img {
        width: 20px;
        height: 20px;
        align-self: center;
        position: relative;
        right: 1px;
      }

      &.carousel-control-right {
        right: 16px;
        left: unset;

        img {
          left: 1px;
        }
      }
    }
  }
}

.fullscreen-button {
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 1;
  cursor: pointer;
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  border: 1.5px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.fade-in {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.fade-in.active {
  pointer-events: all;
  opacity: 1;
}

.primary-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  border-radius: 8px;
  border: 1.5px solid #bababa;
  padding: 8px 12px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &.primary-btn-hover:hover {
    background: #222222;
    color: #fff;
  }

  &.borderless-btn {
    border-color: transparent;
  }

  img {
    width: 16px;
    height: 16px;
  }

  span {
    color: #4c4c4d;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 510;
    line-height: normal;
  }

  &.cta-btn {
    border-color: #222;
    border-width: 1px;

    span {
      color: white;
    }
  }
}

.dropdown {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
  height: 44px;

  &.active {
    border-radius: 8px 8px 0 0;
  }

  &.inverted {
    border-radius: 0 0 16px 16px;
  }

  span {
    flex: 1;
    color: #222;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  img {
    width: 16px;
    height: 16px;
  }

  &_placeholder {
    color: #717171 !important;
    margin-right: 5px;
  }
}

.dropdown-container {
  position: relative;
  display: flex;
  flex: 1;

  .divider {
    position: absolute;
    width: 100%;
    bottom: 0;

    &.inverted {
      bottom: 48px;
      z-index: 2;
    }
  }

  &.sort-dropdown {
    width: 100%;

    .dropdown {
      width: 100%;
      padding: 12px 16px;
    }

    .dropdown-list {
      top: 40px;
      z-index: 100;

      &_item {
        padding: 4px 12px;
        height: auto;
      }

      &.inverted {
        box-shadow: 1px 0px 1px 0px rgba(0, 0, 0, 0.2);
        top: unset;
        bottom: 44px;
      }
    }

    span {
      font-size: 14px;
    }
  }

  &.seat-dropdown {
    width: 100%;
    margin-bottom: 2px;

    .dropdown {
      width: calc(100% - 16px);
      // padding: 12px 16px;
      height: 40px !important;
      border: 1px solid rgba(186, 186, 186, 1);
    }

    .dropdown-list {
      top: 40px;
    }

    span {
      font-size: 14px;
      text-align: left;
    }
  }

  &.standard-dropdown {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #bababa;
    background: white;

    .dropdown {
      width: 100%;
      padding: 12px;
    }

    .dropdown-list {
      top: 35px;
      border-radius: 8px;
      border: 1px solid #bababa;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-top: 0;
      width: calc(100% + 2px);
      left: -1px;

      &_item {
        padding: 4px 4px;
        height: 26px;
      }
    }

    span {
      font-size: 12px;
    }
  }
}

.dropdown-list {
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 0px 0px 16px 16px;
  background: #fff;
  position: absolute;
  top: 34px;
  opacity: 1;
  width: 100%;
  z-index: 1;
  overflow-y: auto;
  max-height: calc(32px * 5);
  transition: opacity 0.2s ease-out;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.05);

  &.no-max-height {
    max-height: unset;
  }

  &_item {
    height: 32px;
    display: flex;
    padding: 0 8px 0 12px;
    justify-content: center;
    gap: 8px;
    align-self: stretch;
    width: 100%;
    cursor: pointer;
    align-items: center;

    &.hovered {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 6px;
    }

    &.cancel {
      padding-left: 9px;
    }

    span {
      flex: 1;
    }

    img {
      width: 18px;
      height: 18px;
      margin-top: 4px;
    }
  }

  &.inverted {
    border-radius: 16px 16px 0 0;
    bottom: 48px;
    top: unset;

    span {
      padding: 4px;
    }
  }
}

.primary-input {
  @include primaryText();
  @include inputOrBtn();
  padding: 16px;
  border: 0;
  flex: 1;
  border-radius: 8px;
  height: 48px;
  width: 100%;

  &::placeholder {
    @include primaryText();
  }
}

.divider {
  height: 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
}

.not-found {
  width: 100%;
  height: 100svh;

  h2 {
    margin-top: calc(50vh - 80px);
  }

  h2,
  p {
    text-align: center;
  }
}

.global-loader {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-out;
  justify-content: center;

  &.global-loader-fade-in {
    pointer-events: all;
    opacity: 1;
  }

  &_container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 16px;
    padding: 32px;
    box-shadow: 0px 2px 20px 3px rgba(0, 0, 0, 0.15);
    align-self: center;
  }

  &_message {
    color: #222;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 32px;
  }

  &_center {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 100px;
    justify-content: center;

    &_loader {
      position: absolute;
      border-radius: 8px;
      overflow: hidden;
      width: 200px;
      height: 40px;
      background-color: #222;

      &_progress {
        overflow: hidden;
        border-radius: 8px;
        position: absolute;
        background-color: #ee3943;
        width: 100%;
        height: 100%;
      }

      span {
        position: absolute;
        top: 7px;
        left: 0;
        width: 100%;
        color: white;
        text-align: center;
        font-size: 16px;
        height: 100%;
        font-weight: bold;
      }
    }
  }
}

.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-out;

  &.fullscreen-container-fade-in {
    pointer-events: all;
    opacity: 1;
  }

  &_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100svh;
  }

  &_content {
    background-color: white;
    border-radius: 16px;
    padding: 16px;
    width: calc(100vw - 80px);
    height: calc(100svh - 80px);
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    z-index: 1;

    .image-carousel {
      height: 100%;

      .carousel-container {
        video {
          width: 100% !important;
          height: 100% !important;
          max-height: unset !important;
        }

        img {
          width: auto !important;
          height: 100% !important;
          max-height: unset !important;
          object-fit: contain;
        }
      }
    }

    .property-virtual-tour {
      height: 100%;
      width: 100%;
    }
  }

  &_close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
}

.icon-btn {
  img {
    margin-right: 4px;
  }
}

.hamburger-menu {
  position: relative;

  &_toggler {
    height: 40px;
    width: 40px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;

    img {
      width: 24px;
      height: 24px;
      align-self: center;
    }
  }

  &_dropdown {
    border-radius: 8px;
    background-color: white;
    width: 140px;
    position: absolute;
    top: 52px;
    right: 0;
    box-shadow: 0px 2px 20px 3px rgba(0, 0, 0, 0.15);

    &_item {
      cursor: pointer;
      padding: 8px;
      display: flex;
      gap: 8px;
      border-bottom: solid 1px rgba(0, 0, 0, 0.05);
      font-size: 14px;

      &:last-child {
        border-bottom: none;
      }

      img {
        width: 20px;
        height: 20px;
        margin-left: 6px;
      }
    }
  }
}

.subscription-end-modal {
  .dialog-close {
    display: none;
  }
}

.free-trial-modal {
  &_features {
    &_feature {
      width: 50%;
      border: solid 1px rgba(225, 228, 235, 1);
      border-radius: 8px;
      padding: 12px;

      &_heading {
        gap: 8px;

        span {
          align-self: center;
          font-weight: 600;
        }

        &_icon {
          background-color: #ffe1db;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          padding: 12px;

          img {
            align-self: center;
            width: 20px;
            height: 20px;
          }
        }
      }

      &_desc {
        color: rgba(113, 113, 113, 1);
      }
    }
  }

  &_download-manual {
    border: solid 1px rgba(225, 228, 235, 1);
    border-radius: 8px;
    padding: 12px;
    background-color: rgba(242, 244, 248, 1);

    &_btn {
      display: flex;
      width: 210px;
      height: 44px;

      span {
        flex: 1;
        text-align: center;
      }
    }
  }

  &_footer {
    justify-content: space-between !important;
    padding: 16px 24px;
  }
}

.pricing-modal {
  max-height: 80vh;

  &_features {
    gap: 16px;
    padding-top: 24px;
    padding-bottom: 24px;

    &_feature {
      width: 50%;

      &_wrapper {
        width: 100%;
        padding: 0px;
      }

      // tick icon to the left
      &_icon {
        display: flex;
        justify-content: center;
        margin-right: 8px;
        height: fit-content;

        img {
          align-self: center;
          width: 20px;
          height: 24px;
        }
      }

      &_heading {
        gap: 8px;

        span {
          align-self: center;
          font-weight: 600;
          margin-bottom: 0.25rem;
        }
      }
    }
  }

  &_footer {
    justify-content: space-between !important;
    padding: 16px 24px;

    &_icons {
      position: absolute !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
      display: flex;
      margin-top: 20px;
    }

    &_icon {
      display: flex;
      justify-content: center;
      padding: 0px;

      &_active {
        align-self: center;
        width: 6px;
        height: 6px;
        background-color: rgba(138, 145, 161, 1);
        border-radius: 50%;
      }

      &_inactive {
        align-self: center;
        width: 6px;
        height: 6px;
        background-color: rgba(225, 228, 235, 1);
        border-radius: 50%;
      }
    }
  }
}

.subscription-end-modal {
  &_plan {
    border-radius: 8px;
    border: solid 1px rgba(225, 228, 235, 1);

    h2 {
      font-weight: 600;
      font-size: 22px;
    }

    &_header {
      &_sub {
        font-size: 14px;
        color: rgba(113, 113, 113, 1);
      }

      small {
        font-size: 12px;
        color: rgba(113, 113, 113, 1);
      }
    }
  }
}

.primary-input-bordered {
  border-radius: 8px;
  border: 1px solid #bababa;
  padding: 12px;
}

.pricing-summary {
  width: 65% !important;
  margin-right: 16px;

  .selected-plan-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .selected-plan-header {
    color: rgba(113, 113, 113, 1);
    font-size: 12px;
    font-weight: 400;
  }

  .selected-plan-body {
    display: flex;
    width: 50%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
  }

  .plan-title {
    font-size: 14px;
    font-weight: bold;
    color: rgba(34, 34, 34, 1);
    width: 100%;
  }

  .plan-subtitle {
    font-size: 14px;
    color: rgba(113, 113, 113, 1);
    width: 100%;
    font-weight: 400;
  }

  .plan-details {
    font-size: 14px;
    color: rgba(113, 113, 113, 1);
    display: flex;
    width: 50%;
    justify-content: end;
    flex-wrap: wrap;
  }

  .dividers {
    width: 1px;
    background-color: rgba(225, 228, 235, 1);
    min-height: 100%;
    margin: 0 16px;
  }

  .plan-billing-cycle {
    font-size: 14px;
    color: rgba(113, 113, 113, 1);
    display: flex;
    width: 100%;
    justify-content: start;
    flex-wrap: wrap;
  }

  .plan-price {
    font-weight: bold;
    width: 100%;
    color: rgba(34, 34, 34, 1);
  }
}

.pricing-table {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  border-collapse: collapse;
  font-family: Inter, sans-serif;
  color: rgba(113, 113, 113, 1);

  .custom-radio {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(34, 34, 34, 1);
    border-radius: 5px;
    cursor: pointer;
    position: relative;
  }

  .custom-radio:checked {
    background-color: rgba(
      34,
      34,
      34,
      1
    ); /* Make the button black when selected */
    border-color: rgba(34, 34, 34, 1);
  }

  .custom-radio:checked::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 14px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='white' stroke-width='1.5' fill='white'%3E%3Cpath d='M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4L9 16.2z'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%); /* Center the checkmark */
  }

  &__pricing {
    .toggle-button {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      height: fit-content;
      border-radius: 8px;
      border: 1px solid #d1d5db !important;
      width: calc(100% - 16px);
      cursor: pointer;

      // on hover

      span {
        font-size: 16px;
        font-weight: 400;
        color: rgba(113, 113, 113, 1);
        padding: 10px 16px !important;
        width: 50%;
        text-align: center;

        &:hover {
          background-color: rgba(225, 228, 235, 0.2);
          outline: 1px solid rgba(186, 186, 186, 0.5);
        }

        &.active {
          background-color: rgba(225, 228, 235, 0.5);
          outline: 1px solid rgba(186, 186, 186, 1);
          color: rgba(34, 34, 34, 1);
          font-weight: 500 !important;
        }

        // first child
        &:first-child {
          border-radius: 8px 0 0 8px;
        }

        // last child
        &:last-child {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }

  .header-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .pricing-table__header {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    text-align: start;
    font-weight: 600;

    .seat-dropdown-label {
      font-size: 14px;
      color: rgba(34, 34, 34, 1);
      font-weight: 400;
      margin-bottom: 4px;
    }

    div {
      // padding: 12px;
      // font-size: 14px;

      // &:nth-child(1) {
      //   padding-bottom: 0px;
      //   padding-left: 0px;
      //   padding-right: 16px;
      // }

      &.tableHeading {
        text-transform: uppercase;

        &:nth-child(2) {
          padding: 12px;
          font-size: 14px;

          background-color: rgba(204, 228, 255, 1);
          color: rgba(0, 122, 255, 1);
          height: fit-content;
          align-self: end;
          border-radius: 8px 0 0 0;
          text-align: center;
        }
        &:nth-child(3) {
          background-color: rgba(198, 246, 210, 1);
          color: rgba(5, 96, 55, 1);
          height: fit-content;
          align-self: end;
          padding: 12px;
          font-size: 14px;
          text-align: center;
        }
        &:nth-child(4) {
          background-color: rgba(34, 34, 34, 1);
          color: #ffffff;
          height: fit-content;
          align-self: end;
          border-radius: 0 8px 0 0;
          padding: 12px;
          font-size: 14px;
          text-align: center;
          display: flex;
          justify-content: center;

          img {
            width: 7px;
            height: 8px;
            margin-left: 1px;
          }
        }
      }
    }
  }

  .pricing-table__pricing {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    text-align: center;
    font-weight: 600;

    div {
      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: 0px;
      padding-right: 0px;
      font-size: 20px;
      font-weight: 600;
      color: rgba(34, 34, 34, 1);
      border: 1px solid #e5e7eb;
      border-bottom: unset;
      font-family: Inter, sans-serif;

      .priceLabel {
        font-size: 14px;
        color: rgba(34, 34, 34, 1);
        font-weight: 400;
      }

      &:nth-child(1) {
        text-align: left;
        padding-left: 0px;
        font-weight: 600;
        color: rgba(113, 113, 113, 1);
        border: unset;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-content: center;
      }

      &:nth-child(3) {
        border-left: unset;
        border-right: unset;
      }
    }
  }

  .pricing-table__features {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    text-align: center;
    background-color: #fff;
    border: 1px solid #e5e7eb;
    border-top: unset;
    border-radius: 8px 0px 8px 8px;

    &_feature {
      &_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none !important;
        padding-left: 0px;

        img {
          width: 20px;
          height: 24px;
        }
      }
    }

    // only 1 div deep
    div {
      font-size: 14px;
      line-height: 1.4;
      color: #374151;

      // add a background color to every other row there are 4 columns in a row
      &:nth-child(8n + 1),
      &:nth-child(8n + 2),
      &:nth-child(8n + 3),
      &:nth-child(8n + 4) {
        background-color: rgba(252, 252, 253, 1);
      }

      &:nth-child(8n + 5),
      &:nth-child(8n + 6),
      &:nth-child(8n + 7),
      &:nth-child(8n + 8) {
        background-color: #fff;
      }

      // the first column out of 4 should have padding on the left
      &:nth-child(4n + 1) {
        text-align: left;
        font-size: 14px;
        color: rgba(113, 113, 113, 1);
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 8px;
        display: flex;
        font-weight: 400;
        align-items: center;
        border-top: 1px solid #e5e7eb;

        &.font-semibold {
          font-weight: 600;
          color: rgba(34, 34, 34, 1);
        }

        // only the first div in the row should have border radius
        &:first-child {
          border-radius: 8px 0 0 0;
        }
      }

      &:nth-child(4n + 2) {
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #e5e7eb;
        border-right: 1px solid #e5e7eb;
        border-top: 1px solid #e5e7eb;
        // border: 1px solid #e5e7eb;
      }

      &:nth-child(4n + 3) {
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #e5e7eb;
      }

      &:nth-child(4n + 4) {
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #e5e7eb;
        &:not(:first-child) {
          border-top: 1px solid #e5e7eb;
        }
      }
    }
  }
}

.pricing-modal {
  max-height: 80vh !important;

  &__features_feature {
    &_desc {
      font-size: 14px;
      color: rgba(113, 113, 113, 1);
      font-weight: 400 !important;
    }
  }

  &_features_feature {
    &_icon {
      display: inline-block;
      vertical-align: middle;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.border-bottom {
  border-bottom: 1px solid #e5e7eb;
}

.error-messages {
  padding-top: 24px;

  .error-message {
    color: rgba(215, 68, 68, 1);
    font-size: 16px;
    font-weight: 400;
  }
}

.inactive {
  color: #d1d5db;
}

.scrollableOverlay {
  top: calc(100% - 77px - 150px);
}

.pricing-table-overflow-plan-selection {
  position: absolute;
  top: calc(100% - 36.6px);
  border: 1px solid #e5e7eb;
  background-color: rgb(252, 252, 253);
  border-radius: 8px 8px 0px 0px !important;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  text-align: center;
  border-top: unset;
  border-radius: 8px 0px 8px 8px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  pointer-events: all !important;

  &_feature {
    &_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none !important;
      padding-left: 0px;

      img {
        width: 20px;
        height: 24px;
      }
    }
  }

  // only 1 div deep
  div {
    font-size: 14px;
    line-height: 1.4;
    color: #374151;

    &:nth-child(4n + 1) {
      text-align: left;
      font-size: 14px;
      color: rgba(113, 113, 113, 1);
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 8px;
      display: flex;
      font-weight: 400;
      align-items: center;
      border-top: 1px solid #e5e7eb;

      &.font-semibold {
        font-weight: 600;
        color: rgba(34, 34, 34, 1);
      }

      // only the first div in the row should have border radius
      &:first-child {
        border-radius: 8px 0 0 0;
      }
    }

    &:nth-child(4n + 2) {
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #e5e7eb;
      border-right: 1px solid #e5e7eb;
      border-top: 1px solid #e5e7eb;
      // border: 1px solid #e5e7eb;
    }

    &:nth-child(4n + 3) {
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #e5e7eb;
    }

    &:nth-child(4n + 4) {
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #e5e7eb;
      &:not(:first-child) {
        border-top: 1px solid #e5e7eb;
      }
    }
  }

  .custom-radio {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(34, 34, 34, 1);
    border-radius: 5px;
    cursor: pointer;
    position: relative;
  }

  .custom-radio:checked {
    background-color: rgba(
      34,
      34,
      34,
      1
    ); /* Make the button black when selected */
    border-color: rgba(34, 34, 34, 1);
  }

  .custom-radio:checked::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 14px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='white' stroke-width='1.5' fill='white'%3E%3Cpath d='M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4L9 16.2z'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%); /* Center the checkmark */
  }
}

.confirm-button {
  width: fit-content;
}

@media screen and (max-width: $mobileWidth) {
  .fullscreen-container {
    &_content {
      background-color: black;
      width: 100vw;
      height: 100svh;
      border-radius: 0;
      padding: 0;
      padding-top: 80px;
    }

    &_close {
      right: 32px;
      top: 24px;
      z-index: 2;
      padding: 4px;
    }

    .google-earth-btn {
      display: none;
    }
  }

  .pricing-table .pricing-table__header div.tableHeading:nth-child(4) {
    border-radius: 0px !important;
  }

  .pricing-table .pricing-table__features div:nth-child(4n + 1):first-child {
    border-radius: 0px !important;
  }

  .pricing-modal-mobile-height {
    max-height: 100svh !important;
    height: 100svh !important;
    border-radius: 0px !important;
  }

  .pricing-table-overflow-plan-selection {
    margin-left: 0;
    margin-right: 0;
  }

  .pricing-modal_footer {
    justify-content: center !important;
  }

  .tableHeading {
    font-size: 12px !important;
  }

  .pricing-table {
    .pricing-table__pricing div {
      font-size: 16px !important;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 8px !important;

      .priceLabel {
        font-size: 12px !important;
        width: 100%;
      }
    }

    .pricing-table__features {
      border-radius: 0px !important;

      div:nth-child(4n + 1) {
        padding: 8px !important;
        font-size: 12px !important;
      }
    }
  }

  .pricing-summary {
    width: 100% !important;
    margin-right: 0 !important;
  }

  .confirm-button {
    width: 100%;
    justify-content: center;
    margin-top: 16px;
  }

  .seat-dropdown {
    width: 100%;
    margin-bottom: 2px;

    .dropdown {
      width: calc(100% - 16px);
      // padding: 12px 16px;
      height: 40px !important;
      border: 1px solid rgba(186, 186, 186, 1);
    }

    .dropdown-list {
      top: 40px;
    }

    span {
      font-size: 14px;
      text-align: left;
    }
  }

  .pricingModalContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .seat-dropdown-mobile {
    width: 100%;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 16px;
  }

  .dropdown {
    width: 100% !important;
  }

  .header-title {
    font-size: 16px !important;
  }

  [role="dialog"] {
    h2 {
      padding-left: 10% !important;
      padding-right: 10% !important;
    }
  }

  .scrollableOverlayPage3 {
    top: calc(100% - 93px - 150px) !important;
  }

  .selected-plan-body {
    width: 100% !important;
  }

  .plan-details {
    width: 100% !important;
    justify-content: start !important;

    .plan-subtitle {
      width: fit-content !important;
    }

    .plan-price {
      width: fit-content !important;
    }

    .plan-billing-cycle {
      width: fit-content !important;
    }
  }

  .selected-plan-container {
    flex-wrap: wrap;
  }

  .scrollableOverlayMobileSelected {
    top: calc(100% - 135px - 150px);
  }

  .toggle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: fit-content;
    border-radius: 8px;
    border: 1px solid #d1d5db !important;
    width: 100%;
    cursor: pointer;
    margin-bottom: 16px;
    margin-left: 16px;
    margin-right: 16px;

    // on hover

    span {
      font-size: 16px;
      font-weight: 400;
      color: rgba(113, 113, 113, 1);
      padding: 10px 16px !important;
      width: 50%;
      text-align: center;

      &:hover {
        background-color: rgba(225, 228, 235, 0.2);
        outline: 1px solid rgba(186, 186, 186, 0.5);
      }

      &.active {
        background-color: rgba(225, 228, 235, 0.5);
        outline: 1px solid rgba(186, 186, 186, 1);
        color: rgba(34, 34, 34, 1);
        font-weight: 500 !important;
      }

      // first child
      &:first-child {
        border-radius: 8px 0 0 8px;
      }

      // last child
      &:last-child {
        border-radius: 0 8px 8px 0;
      }
    }
  }

  .dialog-back-button {
    top: 2rem;
    left: 2rem;
    position: absolute;
    margin: 0 !important;

    div {
      padding: 0px !important;
      margin: 0px !important;
      width: 1.25rem !important;
      height: 1.25rem !important;
    }
  }
}
