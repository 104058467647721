@import "../navbar/navbar.scss";
@import "../core/core.scss";

$property-header-height: 190px;

.property-panel {
  width: $sidePanelWidth;
  height: 100svh;
  position: fixed;
  right: 0;
  top: 0;
  overflow-y: auto;
  border: 4px solid #fff;
  background: rgba(242, 244, 248, 0.9);
  backdrop-filter: blur(25px);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  /* WebKit Browsers */
  ::-webkit-scrollbar {
    width: 12px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
    height: 100px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  &.expanded {
    width: calc(100vw - 88px);
    z-index: 1;
  }

  &_expand {
    position: absolute;
    bottom: 20px;
    height: 40px;
    justify-content: center;
    width: 100%;
    z-index: 2;
    display: flex;

    div {
      padding: 8px 16px 8px 16px;
      height: 40px;
      background: #fff;
      border-radius: 100px;
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

      span {
        font-weight: 500;
        font-size: 12px;
      }

      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  &_collapse {
    height: 40px;
    justify-content: center;
    z-index: 2;
    display: flex;

    div {
      padding: 16px 24px;
      height: 44px;
      background: #222;
      border-radius: 100px;
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      span {
        font-weight: 500;
        font-size: 12px;
        color: white;
      }

      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  &_container {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
    flex: 1;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;

    &_scrollplane {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
      padding-bottom: 24px;
    }

    &_loading-text {
      font-size: 14px;
      font-weight: 600;
      color: #4d4d4c;
    }

    .property-animate-content {
      margin-bottom: 60px;

      &_extra-margin {
        margin-bottom: 160px;
      }
    }
  }

  &.expanded {
    .property-panel_container {
      overflow-y: auto;
    }
  }

  &_property-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 24px;
    padding: 20px;
  }

  &_non-supported-region {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 16px;
    gap: 8px;
    height: 100%;
    margin-top: -20px;

    p {
      font-weight: 500;
    }
  }
}

.property-panel-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background: #fff;
  overflow: hidden;
  position: relative;

  .property-panel-card-title-private-bank {
    width: 100% !important;
  }

  &.hovered {
    box-shadow: 0 10px 8px rgba(0, 0, 0, 0.2);
    transition: 0.2s ease-out;
    transform: scale(1.01, 1.01);
  }

  &.selected {
    // box-shadow: 0 12px 20px rgba(239, 85, 51, 0.6);
    transition: 0.2s ease-out;
    transform: scale(1.01, 1.01);
    outline: solid 2px #ee3943;
    box-shadow: none;
  }

  .image-carousel {
    height: 240px;
    width: 100%;
  }

  &_similarity-score {
    position: absolute;
    top: 15px;
    left: 19px;
    padding: 3px 8px;
    background: rgba(34, 34, 34, 0.65);
    color: white;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    z-index: 1;
    display: flex;
    gap: 4px;

    &_circle {
      border-radius: 50%;
      width: 15px;
      height: 15px;
      align-self: center;

      &.high {
        background: #81c784;
      }

      &.medium {
        background: #ffcb28;
      }

      &.low {
        background: #ff5c8d;
      }
    }

    span {
      font-weight: bold;
    }
  }

  &_checkbox {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 40px;
    height: 40px;
    z-index: 1;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  &_like {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
  }

  &_contents {
    width: 100%;
    display: flex;
    padding: 20px 0px 16px 0px;
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;

    &_section1 {
      padding: 0px 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      .property-panel-card-title {
        margin: 0;
        align-self: stretch;
        color: #4d4d4c;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration: none;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
        }
      }

      &_prices {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        &_main_price {
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;

          .full-price {
            color: #000;
            font-feature-settings:
              "clig" off,
              "liga" off;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            small {
              font-size: 12px;
              font-weight: 400;
            }
          }

          .price-per-m {
            color: #4d4d4c;
            font-feature-settings:
              "clig" off,
              "liga" off;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            align-self: flex-end;
            justify-self: flex-end;
            text-align: right;
            flex: 1;
          }
        }

        &_was_price {
          display: flex;
          align-items: flex-end;
          gap: 8px;
          align-self: stretch;

          .was-price {
            flex: 1;
            font-size: 16px;
          }

          .was-price-diff {
            display: flex;
            align-self: flex-end;
            justify-self: flex-end;

            span {
              font-size: 12px;
            }

            img {
              width: 16px;
              height: 16px;
              position: relative;
              top: 0;
              margin-right: 4px;
            }
          }

          span {
            color: #d74444;
          }
        }
      }
    }
  }

  .property-panel-card-divider {
    height: 1px;
    width: 100%;
    background: rgba(0, 0, 0, 0.05);
  }

  .property-panel-card-size {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    padding-left: 24px;
    padding-right: 16px;
    width: 100%;

    span {
      color: #4d4d4c;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .vertical-divider {
      margin-left: 8px;
      margin-right: 8px;
      height: 16px;
      margin-top: 1px;
    }
  }

  &_footer {
    display: flex;
    width: 100%;
    padding: 0px 24px;
    align-items: center;
    height: 20px;

    &_info {
      display: flex;
      align-items: center;

      span {
        color: rgba(77, 77, 76, 0.6);
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .vertical-divider {
        height: 16px;
        margin-left: 8px;
        margin-right: 8px;
        position: relative;
        top: -1px;
      }
    }

    &_poligon-dot-content {
      display: flex;
      justify-content: left;
      align-items: center;

      .polygon-selection-tray_row_item {
        width: 20px;
      }
    }

    img {
      width: 20px;
      height: 20px;
      margin-right: 0;
      margin-left: auto;
    }
  }

  .upgrade-view {
    height: 240px;
    min-width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .upgrade-text {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: rgba(113, 113, 113, 1);
    padding: 8px 16px;
  }
}

.property-card-chips {
  padding: 0 20px;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;

  &_chip {
    display: flex;
    padding: 6px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background: rgba(225, 228, 235, 0.4);

    &.sold-chip {
      background-color: rgba(248, 176, 180, 1);
      color: rgba(97, 22, 22, 1);
    }

    &.reserved-chip {
      background-color: rgba(253, 243, 210, 1);
      color: rgba(97, 79, 22, 1);
    }

    &.offerReceived-chip {
      background-color: rgba(253, 225, 210, 1);
      color: rgba(97, 58, 22, 1);
    }

    &.available-chip {
      background-color: rgba(198, 246, 210, 1);
      color: rgba(5, 96, 55, 1);
    }

    img {
      width: 16px;
      height: 16px;
    }

    span {
      color: #4d4d4c;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.property-panel-header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: $property-header-height;
  padding: 24px;
  z-index: 1;
  height: 190px;
  background: rgba(242, 244, 248, 1);
  gap: 20px;

  &.expanded {
    height: 92px;
    display: flex;
    flex-direction: row;
    padding: 24px;

    .dropdown-container {
      flex: unset;
    }
  }

  .divider {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 1;

    &.scrolled {
      opacity: 1;
      transition: opacity 0.2s ease-out;
    }
  }

  &_count {
    color: #000;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    width: 100%;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    align-self: stretch;

    &_column {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &_bubble {
      height: 20px;
      border-radius: 20px;
      border: solid 1px white;
      background-color: black;
      position: absolute;
      right: -8px;
      top: -8px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      padding: 4px;
      min-width: 19px;

      span {
        color: white;
        font-size: 11px !important;
      }
    }

    &_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      cursor: pointer;
      border-radius: 8px;
      border: 1.5px solid #bababa;
      padding: 8px 12px;
      position: relative;
      height: 44px;
      padding: 16px 20px;

      &.filters-btn {
        background-color: white;
      }

      &.inactive {
        cursor: default;

        img,
        span {
          opacity: 0.4;
        }
      }

      img {
        width: 16px;
        height: 16px;
      }

      span {
        color: #4c4c4d;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &.active-filters {
        border: 1.5px solid rgba(0, 0, 0, 0.3);
        background: #ee3943;

        span {
          color: white;
        }
      }
    }
  }

  &_sort {
    display: flex;
    align-self: flex-start;
    width: 100%;
    flex-direction: column;

    &_column {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;

      &_header {
        flex: 1;
        color: #717171;
        font-size: 12px;
      }

      &:nth-child(2) {
        margin-top: 8px;
        gap: 12px;
      }
    }

    &_btn {
      cursor: pointer;
      display: flex;
      padding: 8px 12px;
      align-items: center;
      gap: 8px;
      border-radius: 12px;
      background: #fff;

      span {
        color: #4d4d4c;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &_strong {
        color: #222;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .sort-dropdown {
    .dropdown_label-container {
      span {
        font-size: 14px;
      }
    }

    span {
      font-size: 14px;
    }
  }
}

.property-chip {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: #fff;

  span {
    font-size: 12px;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  img {
    width: 16px;
    height: 16px;
  }

  &.selected {
    border: 1.5px solid rgba(0, 0, 0, 0.3);
    background: #ee3943;
    padding: 6.5px 14.5px;

    span {
      color: white;
    }
  }
}

.chip-tooltip {
  z-index: 9999;
}

.search-context-pane {
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  h2 {
    font-size: 18px;
    font-weight: 500;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 30px;
  }

  span {
    font-size: 12px;
    color: rgba(113, 113, 113, 1);
    max-height: 20px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &_controls {
    display: flex;
    gap: 8px;

    &_control {
      display: flex;
      gap: 4px;
      border: solid 1px rgba(186, 186, 186, 1);
      border-radius: 8px;
      padding: 4px;
      justify-content: center;
      padding: 8px 10px;
      cursor: pointer;

      &.inactive {
        opacity: 0.4;
        cursor: initial;
      }

      img {
        width: 16px;
        height: 16px;
        align-self: center;
      }

      &.reset {
        img {
          width: 12px;
          height: 12px;
          margin-right: 2px;
        }
      }
    }
  }

  &.expanded {
    flex-direction: row;
    align-items: center;

    .search-context-pane_search-title {
      flex: 1;
      margin-top: 3px;
    }

    .search-context-pane_controls {
      justify-self: flex-end;
    }
  }
}

.property-panel-loader {
  height: 100px;
}

.polygon-selection-tray_tooltip {
  display: flex;
  flex-direction: column;
}

.property-panel-card-chips-container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  z-index: 1; // Ensure the chips are above the card content
  top: 16px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

.chip {
  padding: 6px 10px;
  border-radius: 8px;
  border-width: 1px;
  opacity: 1;
  display: flex;
  align-items: center;
  box-shadow: 0px 10px 20px 0px rgba(34, 34, 34, 0.1);
  font-weight: 600;

  img {
    color: white;
    width: 16px;
  }

  span {
    font-size: 0.75rem;
    font-weight: 600;
  }
}

.chip-left {
  background-color: rgba(22, 52, 97, 1);
  border: 1px solid rgba(69, 93, 129, 1);
  color: white;

  img {
    color: white; // Ensure the SVG icon is white
    margin-right: 4px;
  }
}

.chip-right {
  background-color: rgba(198, 246, 210, 1);
  border: 1px solid rgba(159, 216, 179, 1);
  color: rgba(5, 96, 55, 1);

  img {
    fill: rgba(5, 96, 55, 1); // Ensure the SVG tick icon matches the text color
    margin-left: 4px;
  }
}

.sold-property-panel-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  position: relative;

  .upgrade-view {
    height: 240px;
    min-width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .upgrade-text {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: rgba(113, 113, 113, 1);
    padding: 8px 16px;
  }

  &-title-sold-properties {
    width: 100% !important;
    height: 240px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_similarity-score {
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 3px 8px;
    background: rgba(34, 34, 34, 0.65);
    color: white;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    z-index: 1;
    display: flex;
    gap: 4px;

    &_circle {
      border-radius: 50%;
      width: 15px;
      height: 15px;
      align-self: center;

      &.high {
        background: #81c784;
      }

      &.medium {
        background: #ffcb28;
      }

      &.low {
        background: #ff5c8d;
      }
    }

    span {
      font-weight: bold;
    }
  }

  &_checkbox {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 40px;
    height: 40px;
    z-index: 1;
  }

  &.selected {
    // box-shadow: 0 12px 20px rgba(239, 85, 51, 0.6);

    transition: 0.2s ease-out;
    transform: scale(1.01, 1.01);
    outline: solid 2px #ee3943;
    box-shadow: none;
  }

  &_image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 240px;
    min-height: 240px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 137px;
    background: linear-gradient(
      to bottom,
      rgba(34, 34, 34, 0.9) 0%,
      rgba(34, 34, 34, 0) 100%
    );
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 24px 24px 12px;
    position: absolute;
    top: 16px;
    left: 16px;
    right: 16px;
    z-index: 2;
    align-items: center;

    &_similarity-score {
      width: 100%;
      display: flex;
      justify-content: end;
    }

    &.selectable-header {
      top: 194px !important;
    }

    &_badge {
      background: #cf4343;
      display: flex;
      align-items: center;
      gap: 8px;

      border-radius: 100px;
      padding: 4px 12px;

      span {
        color: white;
        font-size: 0.9rem;
        font-weight: 600;
      }

      &_date {
        font-weight: 400 !important;
      }
    }

    &_right {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      background: white;
      padding: 4px 12px;
      border-radius: 100px;

      span {
        color: #717171;
        font-size: 1rem;
        font-weight: 400;

        &:first-child {
          color: #717171;
          font-size: 0.8rem;
          font-weight: 500;
        }

        &:last-child {
          font-size: 0.9rem;
          color: #222;
          font-weight: 600;

          // Align the price with the text "Sold in"
          position: relative;
          top: -1px;
        }
      }
    }
  }

  &_body {
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
    margin-top: 0;

    &_title {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 12px;

      span {
        color: #222;
        font-size: 16px;
        font-weight: 500;
      }
    }

    &_sold-for {
      color: #717171;
      font-size: 12px;
      font-weight: 400;
    }

    &_sold-for-price {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      margin-bottom: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        color: #222;

        &:first-child {
          flex: 1;
          font-size: 20px;
          font-weight: 700;
        }

        &:last-child {
          flex: 1;
          font-size: 14px;
          font-weight: 400;
          text-align: right;
        }
      }
    }

    &_size {
      margin-top: auto;
      span {
        color: #717171;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  &_footer {
    padding-top: 12px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &_poligon-dot-content {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &_catastro {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;

      span {
        color: #222;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .polygon-selection-tray_row_item {
      height: unset !important;
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .property-panel {
    width: 100vw !important;
    height: 100svh;
    top: 50px;
  }

  .property-panel-header {
    height: 120px !important;

    &_mobile-close {
      cursor: pointer;
    }
  }

  .search-context-pane {
    background-color: #f2f4f8;
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  }
}

.blurred-text {
  filter: blur(4px);
  user-select: none;
}
