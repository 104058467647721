.embedded-signup [data-amplify-container] [role="tablist"] {
  display: none;
}

.embedded-signup {
  background: transparent !important;
}

.embedded-signup [data-amplify-router] {
  border: none;
  box-shadow: none;
}

.embedded-signup [data-amplify-container] {
  width: 100% !important;
}

.embedded-signup .signup-free-trial-text {
  display: none !important;
}

.embedded-signup .amplify-button--primary {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  transform: scale3d(1none, 1none, 1none);
  color: #fff;
  transform-style: preserve-3d;
  background-color: #ee3943 !important;
  border: 2px solid #dadada !important;
  border-radius: 30px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 15px 30px;
  font-family: Fustat, sans-serif;
  font-size: 18px;
  font-weight: 500;
  transition: transform 0.35s;
  display: flex;
}

.embedded-signup .amplify-button--primary:hover {
  background-color: #a7282f !important;
  padding-top: 12px;
  padding-bottom: 12px;
  transform: scale(1.05);
}
