$navbarWidth: 88px;
$mobileWidth: 1100px;

.navbar {
  width: $navbarWidth;
  height: 100svh;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  display: flex;
  flex-direction: column;
  z-index: 9;

  @media (max-width: $mobileWidth) {
    display: none;
  }

  &_contents {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &_divider {
    width: 100%;
    height: 1px;
    background-color: #e3e6eb;
  }

  &_logo-container {
    padding: 20px;

    &_wrapper {
      display: flex;

      img {
        width: 48px;
        height: auto;
      }
    }
  }

  &_items {
    display: flex;
    padding: 0 20px 0 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1;
    align-self: stretch;
  }

  .navbar-item {
    cursor: pointer;
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 12px;
    position: relative;

    &.disabled {
      opacity: 0.4;
      cursor: initial;
    }

    &.new {
      background: rgba(0, 122, 255, 0.1);
      border-radius: 8px;
      display: flex;
      flex-wrap: wrap;
      position: relative;

      // add margin to bottom of the element to make space for the after element
      // margin-bottom: 26px;

      // &::after {
      //   content: 'NEW';
      //   position: absolute;
      //   // top of the element is on the bottom of the element
      //   top: 100%;
      //   left: 0;
      //   width: 100%;
      //   padding: 2px 4px;
      //   background: #007aff;
      //   color: white;
      //   font-size: 12px;
      //   line-height: 18px;
      //   font-weight: 900;
      //   letter-spacing: 2px;
      //   text-align: center;
      //   border-radius: 0 0 8px 8px;
      //   box-sizing: border-box;
      // }
    }

    img {
      width: 20px;
      height: 20px;
    }

    &.active {
      border-radius: 8px;
      background: #efeeee;
    }

    .notification-badge {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #ee3943;
      color: white;
      font-size: 12px;
      position: absolute;
      top: -4px;
      right: -4px;

      span {
        margin-top: 1px;
      }

      &.overflow {
        width: 30px;
        border-radius: 20px;
        right: -10px;
      }
    }
  }

  &_account-container {
    display: flex;
    flex-direction: column;
    padding: 24px 20px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }

  &_profile-image {
    width: 44px;
    height: 44px;
    border-radius: 44px;
    overflow: hidden;

    img {
      width: 44px;
      height: 44px;
      object-fit: cover;
    }
  }

  &_footer {
    display: flex;
    padding: 26px 16px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    background: #f2f4f8;

    img {
      width: 52px;
      height: 36px;
    }
  }

  .bank-properties-tooltip {
    background-color: rgba(204, 228, 255, 1) !important;
    // border: 2px solid rgba(0, 122, 255, 1) !important;
    color: rgba(0, 54, 113, 1) !important;
    width: 240px !important;
    padding: 12px 16px !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    opacity: 1 !important;
    flex-wrap: wrap !important;
    border-radius: 8px !important;
    // position: relative !important;

    .title {
      font-size: 14px !important;
      font-weight: 600 !important;
      width: 100%;
      line-height: 21px !important;
      margin-bottom: 8px !important;
    }

    .react-tooltip-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }

    .dismiss-btn {
      background: none;
      border: none;
      color: rgba(0, 54, 113, 1);
      font-size: 14px;
      cursor: pointer;
      margin-left: 8px;
      position: absolute;
      top: 12px;
      right: 12px;
    }

    /* Ensure the arrow is styled consistently with the tooltip */
    .react-tooltip-arrow {
      &::before {
        border-color: transparent transparent transparent rgba(0, 122, 255, 1) !important; // Border on the left side only
        border-width: 8px !important; // Adjust the size of the arrow
        transform: translateX(
          -2px
        ) !important; // Slightly shift the arrow to align it properly
      }
    }
  }
}

.navbar-mobile {
  display: none;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  padding-left: 16px;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  z-index: 4;

  @media (max-width: $mobileWidth) {
    display: flex;
  }

  &_menu {
    margin-right: 24px;
  }

  &.hidden {
    display: none !important;
  }

  .navbar-mobile_logo-container_wrapper {
    img {
      width: 40px;
    }
  }
}

.menu-list-background {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  will-change: opacity;
  backdrop-filter: blur(4px);

  &.active {
    opacity: 1;
  }

  .menu-list {
    position: fixed;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100%;
    background: white;
    padding: 32px 24px;
    box-shadow: -4px 0 24px rgba(0, 0, 0, 0.12);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateX(300px);
    will-change: transform;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &.active {
      transform: translateX(-300px);
    }

    &_item {
      display: flex;
      align-items: center;
      padding: 16px;
      margin-bottom: 8px;
      border-radius: 12px;
      cursor: pointer;
      transition: all 0.2s ease;
      user-select: none;

      &:hover {
        background-color: #f8f9fa;
        transform: translateX(4px);
      }

      &:active {
        background-color: #f0f1f2;
        transform: translateX(2px);
      }

      img {
        width: 24px;
        height: 24px;
        margin-right: 16px;
        opacity: 0.9;
      }

      span {
        font-size: 16px;
        color: #1a1a1a;
        font-weight: 500;
      }

      &.active {
        background-color: #f0f1f2;
        font-weight: 600;
      }
    }

    .spacer {
      height: 1px;
      background: #e3e6eb;
      margin: 20px 0;
      opacity: 0.8;
    }

    &_header {
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 1;
    }

    &_profile-image {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      overflow: hidden;
    }

    &_close-button {
      background: none;
      border: none;
      color: #222;
      font-size: 28px;
      cursor: pointer;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }

      span {
        line-height: 1;
        margin-top: -2px;
      }
    }

    &_item:first-of-type {
      margin-top: 32px;
    }
  }
}
