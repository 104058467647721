@import "../../navbar/navbar.scss";
@import "../../core/core.scss";

.property-filter-panel-container {
  width: $sidePanelWidth;
  position: fixed;
  transform: translateX(100%);
  transition: transform 0.2s ease-in-out;
  height: 100svh;
  top: 0;
  right: 0;
  z-index: 2;
}

.property-filter-panel {
  display: flex;
  flex-direction: column;
  width: $sidePanelWidth;
  height: 100svh;
  position: fixed;
  top: 0;
  right: 0;
  padding: 20px;
  overflow-y: auto;
  background: #e1e5e7;
  z-index: 3;

  &_section-header {
    color: #000;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    margin-top: 24px;
    &:first-child {
      margin-top: 90px;
    }
  }

  &_chip-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 12px;

    .property-chip {
      cursor: pointer;
      height: 40px;

      span {
        color: #717171;
        font-size: 14px;
      }
    }
  }

  &_section {
    display: flex;
    gap: 12px;
    width: 100%;
    margin-top: 12px;

    .dropdown {
      flex: 1;
      height: 45px;
      border-radius: 8px;
      span {
        color: #717171;
        font-size: 14px;
      }
    }

    &_land-features {
      cursor: pointer;

      label {
        font-size: 14px;
        overflow: auto !important;
        text-overflow: initial !important;
        white-space: initial !important;
      }
    }

    &:last-child {
      margin-bottom: 110px;
    }
  }

  .primary-input {
    height: 45px;
    &::placeholder {
      color: #717171;
      font-size: 14px;
    }
  }
}

.property-filter-panel-header {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  background: rgba(242, 244, 248, 1);
  z-index: 4;
  padding: 20px;
  width: $sidePanelWidth;
  height: 92px;
  align-items: center;

  .primary-btn {
    border-width: 1px;
  }

  .spacer {
    flex: 1;
  }

  &_clear {
    opacity: 0;
    margin-right: 8px;
    transition: opacity 0.2s ease-in-out;
  }

  &_apply {
    opacity: 0;
    border-radius: 8px;
    border: 1.5px solid rgba(0, 0, 0, 0.3);
    background: #ee3943;
    transition: opacity 0.2s ease-in-out;

    span {
      color: white;
    }
  }

  .divider {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;

    &.scrolled {
      opacity: 1;
      transition: opacity 0.2s ease-out;
    }
  }

  &_property-count {
    margin-left: 8px;
    color: #000;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    align-self: center;
  }
}

.property-filter-panel-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: $sidePanelWidth;
  z-index: 4;
  height: 76px;
  background: rgba(242, 244, 248, 1);
  display: flex;
  align-items: center;

  &_buttons-content {
    height: 44px;
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 12px;
    padding-right: 24px;

    div {
      display: flex;
      padding: 16px;
      width: fit-content;
      align-items: center;
      justify-content: center;
      border-width: 1.5px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      font-family: Inter;
    }

    .clear-btn {
      border-color: #bababa;
      gap: 4px;
      cursor: pointer;

      img {
        width: 20px;
      }
    }

    .apply-btn {
      background-color: #ee3943;
      border-color: #812f1d;
      color: #ffffff;
      cursor: pointer;
    }

    .disabled {
      opacity: 30%;
    }
  }
}

.primary-btn {
  height: 44px;

  span {
    font-size: 16px;
  }
}

@media screen and (max-width: $mobileWidth) {
  .property-filter-panel-container {
    width: 100vw;
    left: 0;
    top: 50px;
    height: calc(100svh - 50px);

    .property-filter-panel {
      width: 100vw;
      left: 0;
      height: calc(100svh - 50px);
    }

    .property-filter-panel-header {
      width: 100vw;
      left: 0;
    }

    .property-filter-panel-footer {
      width: 100vw;
      left: 0;
    }
  }
}
