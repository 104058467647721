.search-input {
  input {
    width: 260px;
    border: 1px solid #cbcbcb;
    border-radius: 8px;
    display: block;
    padding: 9px 4px 9px 40px;
    background: transparent url("../../assets/search/search_icon.svg") no-repeat
      13px;
    width: 100%;
  }
}
