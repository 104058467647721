.login-blurb {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h1 {
    color: var(--White, #fff);
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;

    span {
      background: linear-gradient(90deg, #ee3943 47.67%, #ff6161 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &_container {
    border-radius: 16px;
    border: 1px solid rgba(225, 228, 235, 0.2);
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  }

  &_subheading {
    color: #717171;
    font-size: 16px;
    font-weight: 500;

    @media screen and (max-width: 1050px) {
      text-align: center;
    }

    span {
      color: white;
    }
  }

  &_benefits {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 16px;
    width: 100%;

    @media screen and (max-width: 1050px) {
      display: flex;
      flex-direction: column;
    }

    &_benefit {
      display: flex;
      padding: 12px;
      gap: 12px;
      border-radius: 8px;
      border: 1px solid rgba(225, 228, 235, 0.1);

      img {
        border-radius: 8px;
        background: rgba(253, 228, 215, 0.1);
        height: 44px;
        padding: 12px;
      }

      h2 {
        color: white;
        font-size: 12px;
        font-weight: 600;
      }

      span {
        color: rgba(255, 255, 255, 0.6);
        font-size: 11px;
      }
    }
  }

  &_footer-text {
    color: #717171;
  }

  &_more-features {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 16px;
    width: 100%;

    @media screen and (max-width: 1050px) {
      display: none;
    }

    &_feature {
      display: flex;
      gap: 8px;

      img {
        width: 16px;
        height: 16px;
      }

      span {
        color: white;
        font-size: 12px;
      }
    }
  }
}

.signup-free-trial-text {
  padding: 32px;
  padding-bottom: 0;

  &_header {
    color: #222;
    font-size: 24px;
    font-weight: 600;
    font-size: 20px;
  }

  &_subheader {
    color: #717171;
    font-size: 24px;
    font-weight: 600;
    font-size: 20px;
  }
}
