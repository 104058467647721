.switcher {
  cursor: pointer;

  &_container {
    height: 30px;
    width: 60px;
    background-color: #d1d1d1;
    border-radius: 20px;
    display: flex;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &.is_on {
    .switcher_container {
      background-color: #ee3943;
      border: 1px solid #eeeeee;
    }
  }

  &_slider {
    border-radius: 50%;
    background-color: white;
    width: 20px;
    height: 20px;
    align-self: center;
    margin: 0 4px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &.is_on {
      transform: translateX(30px);
    }
  }
}
