@import "../navbar/navbar.scss";
@import "../core/core.scss";

.district-selection-panel {
  top: 0;
  width: $sidePanelWidth;
  height: 100svh;
  position: fixed;
  right: 0;
  overflow-y: auto;
  border-left: 4px solid #fff;
  background: rgba(242, 244, 248, 1);
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.district-selection-panel-header {
  padding: 24px 24px 16px;
  background: white;

  h3 {
    font-size: 20px;
    margin-top: 0;
  }
}

.district-selection-panel-content {
  overflow: scroll;
  flex: 1;
}

.district-selection-panel-footer {
  background: white;

  display: flex;
  flex-direction: column;

  .primary-btn {
    margin-left: 16px;
  }

  .district-selection-panel-footer-container {
    padding: 16px 24px 0;
    border-bottom: 1px solid #e1e4eb;
    max-height: 160px;
    overflow-y: auto;
    overflow-x: hidden;

    p {
      color: #717171;
      margin: 0 0 16px;
      font-size: 16px;
    }
  }

  .district-selection-panel-footer-bt {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
  }

  .district-selection-panel-footer-right-container {
    justify-content: end;
    display: flex;
    flex-grow: 1;
  }

  .show-map-button {
    margin-left: 0px !important;
  }
}

.district-cb-container {
  width: 100%;
  padding: 12px 24px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #e1e4eb;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
  }

  .checkbox svg {
    margin-right: 8px;
  }

  .district-property-district {
    color: #717171;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    margin-left: 36px;
    margin-top: 2px;
  }

  &.active {
    background-color: #e9e9e9;
  }
}

.selected-districts {
  display: flex;
  flex-wrap: wrap;
}

.selected-district {
  padding: 8px 16px;
  border-radius: 24px;
  font-size: 14px;
  display: flex;
  gap: 4px;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 16px;
  background-color: #ee3943;

  span {
    color: white;
  }

  img {
    cursor: pointer;
    width: 14px;
    height: 14px;
    margin-top: 2px;
  }
}

.district-selection-panel-content {
  flex: 1;
}

.district-selection-panel-inner {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.district-selection-loading-districts {
  align-self: center;
  text-align: center;
}

.district-section-title {
  padding: 24px;
  font-size: 16px;
  font-weight: 600;
  color: #222;
  text-align: center;
  border-bottom: 1px solid #e1e4eb;
  background-color: #e1e4eb;
}

.show-district-button {
  position: absolute;
  background-color: white;
  left: 24px;
  bottom: 30px;
}

@media screen and (max-width: $mobileWidth) {
  .district-selection-panel {
    width: 100vw;
  }
}
