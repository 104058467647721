.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 1000;
    backdrop-filter: blur(2px);
  }
}

.spinner {
  display: inline-block;
  position: relative;

  // Size variants
  &.small {
    width: 24px;
    height: 24px;
  }

  &.medium {
    width: 40px;
    height: 40px;
  }

  &.large {
    width: 64px;
    height: 64px;
  }
}

.spinner-ring {
  position: relative;
  width: 100%;
  height: 100%;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-radius: 50%;
    animation: spinner-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ee3943 transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes spinner-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
