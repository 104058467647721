@import "../navbar/navbar.scss";
@import "../core/core.scss";

.notifications-overlay {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  background-color: rgba(0, 0, 0, 0.5);
}

@keyframes overlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.notifications-panel {
  position: fixed;
  z-index: 3;
  top: 24px;
  left: $navbarWidth + 24px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 388px;
  height: calc(100svh - 44px);
  overflow: hidden;

  @media screen and (max-width: $mobileWidth) {
    height: calc(100svh - 50px);
    max-height: calc(100svh - 50px);
  }

  &_spacer {
    flex: 1;
  }

  &_header {
    padding: 16px 24px;
    position: relative;
    display: flex;
    gap: 12px;
    align-content: center;
    flex-wrap: wrap;

    h3 {
      font-weight: 600;
      font-size: 20px;
    }

    &_badge {
      margin-top: 1px;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #ee3943;
      color: white;
      font-size: 12px;

      span {
        margin-left: -1px;
        margin-top: 1px;
      }

      &.overflow {
        width: 30px;
        border-radius: 20px;
      }
    }

    img {
      width: 18px;
      height: 18px;
      cursor: pointer;
      align-self: center;
    }
  }

  &_controls {
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
    gap: 12px;
    font-size: 14px;

    &_item {
      padding-bottom: 8px;
      cursor: pointer;

      span {
        font-size: 14px;
        padding-left: 12px;
        padding-right: 12px;
        color: #717171;
      }

      &.active {
        border-bottom: 3px solid #007aff;
        font-weight: 600;

        span {
          color: #222;
        }
      }
    }

    &_mark-all {
      display: flex;
      gap: 2px;
      justify-content: center;
      cursor: pointer;

      span {
        color: #007aff;
      }

      img {
        width: 20px;
        height: 20px;
        position: relative;
        top: 1px;
      }
    }
  }

  &_body {
    overflow: auto;
    flex-grow: 1;

    &_empty {
      padding: 24px;
      font-size: 16px;
      color: #717171;
      text-align: center;
    }

    &_item {
      display: flex;
      padding: 16px 24px;
      padding-left: 0;
      gap: 12px;
      cursor: pointer;
      position: relative;

      &_spacer {
        width: 12px;
      }

      &.unread {
        background-color: #edf3ff;
      }

      &_unread-dot {
        background-color: #007aff;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 36px;
        left: 8px;
      }

      &_thumbnail {
        img {
          width: 80px;
          height: 50px;
          border-radius: 4px;
          object-fit: cover;
        }
      }

      &_content {
        flex: 1;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #717171;

        strong {
          color: #222;
        }

        &_search {
          font-weight: 600;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        &_property,
        &_collection {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      &_actions {
        .hamburger-menu {
          margin-top: -6px;
          width: 30px;

          .hamburger-menu_dropdown {
            background-color: white;
            z-index: 4;
            border: solid 1px rgba(0, 0, 0, 0.05);
          }

          &_toggler {
            background-color: transparent;
          }
        }

        width: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        margin-right: -4px;
        font-size: 14px;
        color: #717171;
      }
    }
  }

  .divider {
    background: rgba(205, 205, 205, 0.5);
  }

  @media (max-width: $mobileWidth) {
    left: 0;
    width: 100vw;
    border-radius: 0;
    height: calc(100svh - 20px);
    max-height: unset;

    &_header {
      padding-top: 40px;

      * {
        display: none;
      }
    }
  }
}
