.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-header {
  margin: 8px auto;
  margin-bottom: 4px;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}

#header-logo {
  width: 80px;
  height: auto;
}

.App-link {
  color: #61dafb;
}

.map-container {
  width: calc(100vw - 88px);
  height: 100svh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.login-wrapper {
  background: linear-gradient(0deg, #2a2e3d, 45%, black);
  display: flex;
  justify-content: center;
}

.login-container {
  padding-top: 60px;
  padding-left: 60px;
  padding-right: 60px;
  flex: 1;
  height: auto;
  /* align-items: center; */
  max-width: 1200px;
  display: block;
  margin: 0 auto;
}

.logo-container {
  margin-bottom: 20px;
}

.login-logo {
  height: 80px;
  margin-bottom: 40px;

  img {
    width: 80px !important;
    z-index: 1;
    position: relative;
  }
}

[data-amplify-container] {
  display: flex;
  align-items: center;
  height: 100svh;
  flex-direction: column;
  flex-grow: 1;
  width: 400px !important;
}

[data-amplify-router] {
  width: 100% !important;
  z-index: 1;
  height: 100%;
}

.login-footer {
  position: fixed;
  bottom: 20px;
  z-index: 1;

  p {
    color: white;
  }
}

.login-row {
  gap: 60px;
}

.amplify-button--primary {
  background-color: #222 !important;
  border-color: #222 !important;
}

.amplify-tabs-item[data-state="active"] {
  border-color: #ee3943 !important;
}

.amplify-button--small {
  color: black !important;
}

.amplify-button--small:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: white !important;
}

.amplify-tabs-item[data-state="active"] {
  color: black !important;
}

[data-amplify-router] {
  border-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
}

.login-bg {
  position: fixed !important;
  width: 100vw !important;
  height: 100svh !important;
  object-fit: cover !important;
  left: 0 !important;
  top: 0 !important;
  z-index: 0;
  opacity: 0.4 !important;
}

.amplify-button--primary {
  color: white !important;
}

.loader-logo {
  width: 100px;
  height: auto;
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

[data-amplify-router] {
  width: 100% !important;
}

[data-amplify-authenticator] {
  width: 100% !important;
}

.amplify-tabs {
  display: flex;
  justify-content: flex-start;
  /* Align items to the start */
}

.amplify-tabs > .amplify-tabs-item:nth-child(2) {
  order: 1;
  /* Move the 'create account' tab to the first position */
}

.amplify-tabs > .amplify-tabs-item:nth-child(1) {
  order: 2;
  /* Move the 'sign in' tab to the second position */
}

@media (max-width: 1050px) {
  body {
    overflow: hidden;
  }

  .login-container {
    overflow-y: scroll;
    padding: 0;
    padding-top: 60px;
    flex: unset;

    scrollbar-width: none;
    /* Hide scrollbar for Firefox */
    -ms-overflow-style: none;

    /* Hide scrollbar for IE and Edge */
    &::-webkit-scrollbar {
      display: none;
      /* Hide scrollbar for Chrome, Safari, and Opera */
    }
  }

  .login-blurb h1 {
    text-align: center;
  }

  .login-logo {
    justify-content: center;
  }

  .login-row {
    flex-direction: column;
    gap: 40px;
  }

  .login-blurb {
    max-width: calc(100% - 40px);
    align-self: center;
  }

  .login-form {
    width: 100%;
  }

  [data-amplify-container] {
    height: auto;
    padding-bottom: 300px;
    width: 100% !important;
  }

  [data-amplify-router] {
    height: auto;
    max-width: calc(100% - 40px) !important;
    border-radius: 20px;
  }

  .signup-free-trial-text {
    display: none;
  }
}
