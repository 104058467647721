.spin-wheel-animation {
  width: 100px;
  height: 100px;
  justify-content: center;
  display: flex;
  width: 100%;
}

.house-animation {
  width: 200px;
  height: 200px;
}
