.checkbox {
  display: flex;
  align-items: center;

  svg {
    height: 20px;
    width: 20px;
    min-width: 0px;
    flex-shrink: 0;
  }

  label {
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    margin-left: 8px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
