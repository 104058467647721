@import "../../navbar/navbar";

$detailsMaxWidth: 1200px;
$detailsPadding: 40px;
$detailsCardsMaxWidth: 795px;
$propertyHeaderHeight: 116px;

.details-backdrop {
  background-color: #f2f4f8;
  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.property-details {
  margin-left: $navbarWidth;
  display: flex;

  &_container {
    max-width: $detailsMaxWidth;
    justify-content: space-between;
    flex: 1;
  }

  &_header {
    position: absolute;
    overflow-x: hidden;
    top: 0;
    width: calc(100vw - 89px);
    display: flex;
    padding: 24px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    background-color: white;
    border-bottom: solid 1px #e1e4eb;
    z-index: 2;
    height: $propertyHeaderHeight;

    @keyframes fixed-header-animation {
      0% {
        top: -$propertyHeaderHeight;
      }

      100% {
        top: 0;
      }
    }

    &.fixed-header {
      position: fixed;
      top: -$propertyHeaderHeight;
      animation: fixed-header-animation 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      animation-fill-mode: forwards;
    }

    &_wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: $detailsMaxWidth;
      padding-left: $detailsPadding;
      padding-right: $detailsPadding;
      position: relative;
    }

    &_title {
      display: flex;
      height: 24px;
      gap: 16px;

      span {
        color: #222;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &_action-buttons {
        display: flex;
        align-items: center;
        gap: 24px;

        img {
          cursor: pointer;
          width: 24px;
          height: 24px;
        }
      }

      &_unlisted {
        display: flex;
        padding: 4px 8px;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid #fce6aa;
        background: #fdf3d2;

        span {
          color: #614f16;
          font-size: 14px;
        }
      }
    }

    &_subheader {
      display: flex;
      margin-top: 13.5px;

      &_price {
        display: flex;
        align-items: center;
        gap: 8px;

        .price {
          color: #222;
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .price-per-m2 {
          color: #222;
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .vertical-divider {
        height: 26px;
        margin-left: 16px;
      }

      &_was_price {
        display: flex;
        gap: 8px;

        @media screen and (max-width: 1255px) {
          display: none;
        }

        span {
          color: #d74444;
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .was-price-diff {
          display: flex;
          position: relative;

          img {
            top: 0;
            position: absolute;
          }

          span {
            position: relative;
            top: -2px;
            margin-left: 20px;
          }
        }
      }

      &_features {
        margin-left: 16px;
        display: flex;
        gap: 8px;
      }

      &_area {
        margin-left: 16px;

        span {
          color: #717171;
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }

    &_like-report-content {
      display: flex;
      min-width: 222px;
      height: 44px;
      justify-content: flex-end;
      position: absolute;
      right: 0;

      span {
        color: #222222;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .like-btn {
        display: flex;
        justify-content: center;
      }
    }

    &.mobile {
      display: flex;
      width: 100%;
      height: 60px;
      padding: 9px 16px;

      .property-details_header_wrapper {
        height: 100%;
      }

      .property-details_header_title-subtitle {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        overflow: hidden;
      }

      .property-details_header_title {
        padding: 0;
        gap: 12px;

        span {
          font-size: 12px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .property-details_header_subheader {
        padding: 0;
        margin: 0;
      }

      .property-details_header_subheader_price {
        padding: 0;

        span {
          font-size: 16px;
        }
      }

      .property-details_header_like-report-content {
        min-width: 44px;
        margin-right: 12px;
      }

      .like-btn,
      .report-btn {
        min-width: 44px;
        border: none;
      }
    }
  }

  &_container {
    display: flex;
  }

  .horizontal-divider {
    width: 100%;
    height: 1px;
    background: rgba(225, 228, 235, 0.5);
  }

  &_wrapper {
    padding-left: $detailsPadding;
    max-width: $detailsCardsMaxWidth;
    margin-top: $propertyHeaderHeight;

    .details-card {
      margin-bottom: 16px;
      background-color: white;
      border-radius: 16px;

      &_contents {
        padding: 0px 24px;
      }
    }

    .property-card {
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .image-carousel {
        img {
          max-height: 450px;
        }
      }

      .geo-buttons {
        display: flex;
        gap: 8px;
        position: absolute;
        top: 32px;
        right: 32px;

        img {
          width: 20px;
          margin-right: 8px;
        }
      }

      &_contents {
        padding: 0px 24px;

        &_controls {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          align-self: stretch;
          height: 44px;
          margin-top: 16px;
          margin-bottom: 16px;

          .geolocator-btn img {
            width: 12px;
          }

          .geolocator-btn,
          .wc-btn {
            img {
              margin-right: 8px !important;
            }
          }

          .wc-btn img {
            width: 16px;
          }

          &_media-buttons {
            display: flex;
            gap: 12px;
          }

          &_functions {
            display: flex;
            gap: 12px;

            button {
              display: flex;
              gap: 4px;
              height: 43px;

              &:first-child {
                padding-left: 8px;
              }

              img {
                align-self: center;
              }

              span {
                justify-self: center;
                align-self: center;
              }
            }
          }

          .primary-btn {
            padding: 12px;

            &.active {
              background-color: #ee3943;
              border-color: white;
            }
          }
        }

        .property-card-title {
          color: #222;
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 16px;
          margin-bottom: 0;
        }

        &_section1 {
          padding-bottom: 16px;

          &_prices {
            margin-top: 12px;

            &_main_price {
              display: flex;
              color: #222;
              font-feature-settings:
                "clig" off,
                "liga" off;
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              .full-price {
                font-size: 28px;
                font-weight: 600;
                flex: 1;

                small {
                  font-size: 16px;
                  font-weight: 400;
                }
              }
            }

            &_was_price {
              margin-top: 8px;
              display: flex;
              color: #d74444;
              font-feature-settings:
                "clig" off,
                "liga" off;
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              .was-price {
                flex: 1;
              }

              .was-price-diff {
                display: flex;
                position: relative;
              }

              img {
                position: relative;
                top: 0;
                margin-right: 4px;
              }
            }
          }
        }

        .property-card-chips {
          padding-top: 16px;
          padding-bottom: 16px;
          padding-left: 0 !important;
        }

        .property-card-size {
          display: flex;
          padding-bottom: 16px;

          span {
            color: #717171;
            font-feature-settings:
              "clig" off,
              "liga" off;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .vertical-divider {
            margin-left: 8px;
            margin-right: 8px;
            height: 16px;
            margin-top: 1px;
          }
        }

        .property-card_footer {
          padding: 16px 0;
          display: flex;

          span {
            flex: 1;
            color: #717171;
            font-feature-settings:
              "clig" off,
              "liga" off;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          img {
            width: 20px;
            height: 20px;
            cursor: pointer;

            &:first-of-type {
              margin-right: 16px;
            }
          }
        }
      }
    }

    .details-card {
      overflow: hidden;

      &_title {
        margin: 0;
        padding: 16px 0;
        color: #212121;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      &_description {
        color: #717171;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 137.5% */
        padding-bottom: 16px;
        padding-top: 16px;
      }

      &_location-description {
        padding: 16px 0;
        margin-bottom: 16px;

        p {
          margin: 0;
          margin-bottom: 4px;
          color: #717171;
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          &:last-child {
            margin: 0;
          }
        }
      }

      &_map {
        margin-top: -10px;

        img {
          width: calc(100% + 48px);
          height: auto;
          position: relative;
          left: -24px;
          top: 0;
          max-width: none;
        }
      }
    }
  }
}

.property-details_listing-wrapper {
  margin-right: $detailsPadding;
}

.listings-widget {
  padding: 24px;
  border-radius: 16px;
  left: 900px;
  top: 130px;
  width: 389px;
  background-color: white;
  margin-top: $propertyHeaderHeight + 16px;
  margin-left: 16px;

  p {
    margin: 0;
  }

  &_tabs {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;

    &_tab {
      display: flex;
      cursor: pointer;
      flex: 1;
      justify-content: center;
      padding-bottom: 16px;
      font-size: 18px;
      color: #222;

      &.active {
        border-bottom: solid 2px #222;
      }
    }
  }

  &_title {
    color: #212121;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &_listing {
    margin-top: 24px;

    &_header {
      display: flex;
      gap: 12px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      img {
        width: 42px;
        height: 42px;
        border-radius: 50%;
      }

      &_title {
        display: flex;
        flex-direction: column;
        color: #222;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        .listing-agent {
          color: #717171;
        }
      }
    }

    &_body {
      margin-top: 16px;
      margin-left: 20px;
      padding-left: 32px;
      border-left: solid 1px #e1e4eb;
      display: flex;
      flex-direction: column;
      position: relative;

      &_reference {
        display: flex;
        flex-direction: column;
        gap: 4px;
        color: #717171;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        .ref-num {
          color: #222;
          margin-top: 4px;
        }
      }

      &_contact {
        display: flex;
        gap: 16px;
        margin-top: 8px;
      }
    }
  }
}

.listing_history {
  .listings-widget_listing_header {
    text-decoration: none !important;
  }

  .listings-widget_listing_header_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -5px;
    flex: 1;

    span {
      font-size: 20px;
      font-weight: 700;
      color: #222;
      margin-right: 8px;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }

  .listings-widget_listing_body {
    margin-top: 0;
  }

  &_price-change-up {
    color: green !important;
  }

  &_price-change-down {
    color: red !important;
  }
}

.property-virtual-tour {
  height: 450px;
  width: 100%;
}

.property-virtual-tour-container {
  height: 450px;
  width: 100%;
  position: relative;
}

.property-chat {
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  padding-top: 10px;
  border-left: solid 1px #e1e4eb;
  animation: slideIn 0.2s ease-in-out;

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(400px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideOut {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 1;
      transform: translateX(400px);
    }
  }

  &.slide-out {
    animation: slideOut 0.2s ease-in-out;
    animation-fill-mode: forwards;
  }

  &_header {
    padding: 24px;
    display: flex;
    gap: 8px;
    align-items: center;
    padding-top: 12px;
    height: 44px;

    h3 {
      flex: 1;
      color: #222;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    img {
      cursor: pointer;
    }
  }

  &_chatlog {
    flex: 1;
    overflow-y: auto;
    display: flex;
    gap: 40px;
    flex-direction: column;
    padding-left: 24px;
    padding-top: 24px;
    padding-right: 24px;
    padding-bottom: 40px;

    /* Hide scrollbar for webkit browsers */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    /* Hide scrollbar for Firefox */
    scrollbar {
      display: none;
    }

    &_message {
      background-color: #ffe1db;
      color: #222;
      padding: 12px;
      border-radius: 8px;
      position: relative;
      word-break: break-word;

      &.user {
        &:before {
          content: "";
          position: absolute;
          bottom: -16px;
          right: 0;
          width: 0;
          height: 0;
          border-top: 20px solid transparent;
          border-right: 20px solid #ffe1db;
          border-bottom: 20px solid transparent;
        }
      }

      &.assistant {
        background-color: #f2f4f8;

        &:before {
          content: "";
          position: absolute;
          bottom: -16px;
          left: 0;
          width: 0;
          height: 0;
          border-top: 20px solid transparent;
          border-left: 20px solid #f2f4f8;
          border-bottom: 20px solid transparent;
        }
      }
    }
  }

  &_footer {
    height: 76px;
    display: flex;
    padding-left: 24px;
    padding-right: 24px;

    &_wrapper {
      height: 44px;
      display: flex;
      gap: 8px;
      flex: 1;
      align-self: center;

      input {
        flex: 1;
        border: 1px solid #e1e4eb;
        border-radius: 8px;
        padding: 8px;
      }

      .send-btn {
        background-color: #ee3943;
        border: 1.5px solid rgba(34, 34, 34, 0.3);
        padding: 12px;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }
}

.ai-property-chat-btn {
  position: fixed;
  bottom: 24px;
  right: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &_popup {
    width: 188px;
    font-size: 12px;
    position: relative;

    &_rect {
      background-color: white;
      padding: 16px;
      border-radius: 8px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
    }

    &_nibble {
      position: absolute;
      width: 20px;
      height: 20px;
      bottom: -14px;
      right: 24px;
    }
  }

  &_btn {
    display: flex;
    gap: 8px;
    border-radius: 8px;
    background-color: #ee3943;
    padding: 8px 16px;
    color: white;
    cursor: pointer;
    font-weight: 500;
    border: 1.5px solid rgba(34, 34, 34, 0.3);

    &:hover {
      opacity: 0.9;
      transition: all 0.3s;
    }
  }
}

.translate-loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 12px;
  display: flex;

  span {
    align-self: center;
    text-align: center;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    color: white;
  }
}

.report-modal {
  &_subtitle {
    width: 100%;
    display: flex;
    justify-content: center;

    p {
      width: 552px;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #717171;
    }
  }

  &_reasons-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;

    &_reason {
      width: 268px;
      height: 44px;
      display: flex;
      align-items: center;
      padding-left: 12px;
      border-radius: 8px;
      border-width: 1px;
      border-color: #e1e4eb;
    }
  }

  &_text-input-content {
    width: 600px;
    padding: 0 10px;

    &_duplicate-url-label-input {
      span {
        color: #a6a6a6;
        padding-left: 4px;
      }
    }

    p {
      margin-top: 20px;
    }
  }
}

.property-details_header_bank_chip_container {
  position: relative; // Position it relative to the parent element
  display: flex;
  align-items: center;
}

.property-details_header_bank_chip {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 8px;
  background-color: rgba(22, 52, 97, 1); // Dark blue background
  border: 1px solid rgba(69, 93, 129, 1); // Border color
  color: white !important; // White text color
  box-shadow: none; // Remove box shadow
  font-weight: 600; // Bold text

  img {
    margin-right: 8px; // Space between the icon and text
    width: 16px; // Set icon width
    height: 16px; // Set icon height
    fill: white; // Ensure the SVG icon is white
  }

  span {
    font-size: 0.75rem; // Smaller text size
    font-weight: 600; // Bold text
    color: white !important; // White text color
  }
}

.property-card_value_chip_container {
  position: relative; // Position it relative to the parent element
  display: flex;
  align-items: center;
  flex: 4 1;
}

.property-card_value_chip {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 8px;
  background-color: rgba(198, 246, 210, 1); // Light green background
  border: 1px solid rgba(159, 216, 179, 1); // Green border color
  color: rgba(5, 96, 55, 1) !important; // Dark green text color
  box-shadow: none; // Remove box shadow
  font-weight: 600; // Bold text
  margin-left: 8px;

  img {
    margin-left: 8px; // Space between the icon and text
    width: 16px; // Set icon width
    height: 16px; // Set icon height
    fill: rgba(5, 96, 55, 1); // Ensure the SVG icon matches the text color
  }

  span {
    font-size: 0.75rem; // Smaller text size
    font-weight: 600; // Bold text
    color: rgba(5, 96, 55, 1) !important; // Dark green text color
  }
}

.contact-form-widget {
  padding: 24px;
  border-radius: 16px;
  width: 389px;
  background-color: white;
  margin-top: $propertyHeaderHeight + 16px;
  margin-left: 16px;

  .contact-form-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding: 2rem;

    .success-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      img {
        width: 5rem;
        height: 5rem;
      }
    }

    .success-tagline {
      font-size: 14px;
      color: rgba(34, 34, 34, 1);
      margin-bottom: 16px;
      font-weight: 400;
      line-height: 21px;
      margin-top: 12px;
    }
  }

  p {
    margin: 0;
  }

  &_title {
    color: #212121;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
  }

  &_field {
    margin-top: 16px;

    // if it's the first field, add margin-top
    &:first-of-type {
      margin-top: 24px !important;
    }

    label {
      display: block;
      color: var(--Primary-Text, rgba(34, 34, 34, 1));
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      margin-bottom: 4px;
    }

    input,
    textarea,
    .react-tel-input .form-control {
      width: 100%; // Ensures the input fills the width of the container
      height: 44px;
      padding: 10px 12px;
      border: 1px solid rgba(186, 186, 186, 1); // Updated border color
      border-radius: 8px;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      color: rgba(34, 34, 34, 1);
      box-sizing: border-box; // Ensure padding and border are included in the element's total width and height
    }

    textarea {
      min-height: 100px;
    }

    .error {
      color: red;
      font-size: 14px;
      margin-top: 4px;
      display: block;
    }
  }

  &_cta {
    margin-top: 24px;
    text-align: center;

    .cta-tagline {
      font-size: 14px;
      color: rgba(34, 34, 34, 1);
      margin-bottom: 16px;
      font-weight: 400;
      line-height: 21px;
    }

    .cta-button {
      width: 100%;
      padding: 12px;
      color: white;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-transform: none;
    }
  }

  // Additional styling for the PhoneInput to match other inputs
  .react-tel-input {
    display: flex;
    align-items: center;

    .flag-dropdown {
      border: 1px solid rgba(186, 186, 186, 1); // Match other inputs' border
      border-radius: 8px 0 0 8px; // Left rounded border to match the input's border radius
      background: none; // Remove any default background
      margin-right: 0; // Reset margin to avoid merging into input
      padding-left: 8px; // Add some padding for better spacing
    }

    .phone-input {
      margin-left: 47px;
    }

    .form-control {
      flex: 1;
      padding: 8px;
      border: 1px solid rgba(186, 186, 186, 1); // Match other inputs' border
      border-radius: 0 8px 8px 0; // Right rounded border to complement the dropdown
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      color: rgba(34, 34, 34, 1);
      box-sizing: border-box;
    }

    .country-list {
      border-radius: 8px; // Consistent border radius for dropdown list
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Subtle shadow for dropdown
      border: 1px solid rgba(186, 186, 186, 1); // Match the border color of inputs
    }

    .selected-flag {
      padding-left: 4px;
    }
  }
}

.hidden {
  display: none !important;
}

.windows-card-dropdown {
  height: 44px;
  border-color: #bababa;
  border-width: 1px;
  border-radius: 8px;

  .dropdown {
    height: 42px;
    width: 100%;
  }

  .dropdown_label-container span {
    font-size: 16px;
  }
}

@media (max-width: $mobileWidth) {
  body {
    overflow: auto;
  }

  .property-details {
    margin-left: 0;
    margin-top: 58px;
    width: 100%;

    &_header {
      display: none;
    }

    &_container {
      padding-left: 0;
      flex-direction: column;
      width: 100%;
    }

    &_wrapper {
      padding-left: 0;
      margin-top: 0;
      width: 100%;
      max-width: 100%;

      .details-card {
        border-radius: 0px;
      }
    }

    .property-card {
      border-radius: 0px;

      &_contents {
        padding: 0px 16px;
      }

      &_footer {
        padding: 16px;
      }

      .details-card {
        &_contents {
          padding: 0px 16px;
        }
      }
    }

    .listings-widget {
      width: 100%;
      border-radius: 0px;
      margin: 0px;
    }

    .contact-form-widget {
      width: 100%;
      border-radius: 0px;
      margin: 0px;
    }
  }

  .property-chat {
    width: 100%;
  }

  .property-details_listing-wrapper {
    margin-right: 0;
  }
}

@media screen and (max-width: 535px) {
  .property-card_contents_controls {
    flex-direction: column;
    gap: 16px;
    height: fit-content !important;
  }
}

.geolocate-ai-btn {
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.chart-card {
  background: #fff;
  border-radius: 8px;
  margin-bottom: 16px;
}

.details-card_chart {
  position: relative;
  padding: 16px;
  height: 332px;
}

.details-card_statistics {
  padding: 16px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 24px;

  &_item {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &_label {
      color: #717171;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &_value {
      color: #222;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;

      &.positive {
        color: #056037;
      }

      &.negative {
        color: #d74444;
      }

      .unit {
        font-size: 14px;
        font-weight: 400;
        margin-left: 4px;
      }
    }

    &_subtext {
      color: #717171;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  @media (max-width: $mobileWidth) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    padding: 16px;

    &_item {
      &_value {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}
