.slide-in-enter-active,
.slide-in-enter-done {
  transform: translateX(0) !important;
  transition: transform 0.2s ease-in-out;
}

.fade-in-enter-active,
.fade-in-enter-done {
  opacity: 1 !important;
  transition: opacity 0.2s ease-in-out;
}
