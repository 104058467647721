.upload-polygon-page {
  background: #f2f4f8;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.upload-polygon-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #222;
  margin-top: 60px;
}

.upload-polygon-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;

  input,
  select,
  textarea {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    padding-right: 20px;
  }

  textarea {
    min-height: 300px;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}
