.onboarding-page {
  height: 100%;

  .dropdown {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .dropdown-list {
    padding-top: 12px;
    padding-left: 0;
    transform: translateX(-12px);
    width: calc(100% + 24px);
    max-height: unset;
  }

  .text-cta {
    color: #ee3943;
  }

  input {
    padding-left: 0;
  }

  &_submit {
    background-color: #ee3943;
    border: none;

    &:hover {
      opacity: 0.9;
    }
  }
}
